import { FunctionalComponent } from "preact";
import { useState, useEffect } from "preact/hooks";
import { CurlCommandGenerator } from "./CurlCommandGenerator.tsx";

interface CurlCommandProps {
  selectedServer: { url: string; description: string } | null;
  selectedPath: string | null;
  selectedMethod: string | null;
  selectedParameters: any[];
  selectedResponses: any[];
  methods: Record<string, any>;
}

// Main CurlCommand component
export const CurlCommand: FunctionalComponent<CurlCommandProps> = ({
  selectedServer,
  selectedPath,
  selectedMethod,
  selectedParameters,
  selectedResponses,
  methods,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // Fetch the access token and set authentication status
  const fetchAuthStatus = async () => {
    try {
      const response = await fetch("/api/oauth_checkTokens", {
        method: "GET",
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setIsAuthenticated(!!data.accessToken); // Set authentication status based on access token presence
      } else {
        setIsAuthenticated(false); // No authentication if response is not OK
      }
    } catch (error) {
      console.error("Error fetching authentication status:", error);
      setIsAuthenticated(false); // No authentication on error
    }
  };

  // Fetch the authentication status when the component is first rendered
  useEffect(() => {
    fetchAuthStatus();
  }, []);

  return (
    <div class="relative mt-4 mb-4 p-4 bg-white shadow rounded-md">
      <h5 class="font-bold mb-2">Generated curl Command (just for demo):</h5>

      {/* Generates the curl command based on selected parameters */}
      <CurlCommandGenerator
        selectedServer={selectedServer}
        selectedPath={selectedPath}
        selectedMethod={selectedMethod}
        selectedParameters={selectedParameters}
        selectedResponses={selectedResponses}
        methods={methods}
        isAuthenticated={isAuthenticated} // Pass only the authentication status
      />
    </div>
  );
};
